<template>
  <div>
    <div class="block block-rounded block-fx-pop">
      <div class="block-content block-content-full">
        <DataTable
          :items="reports"
          :total="reportsTotal"
          :loading="reportsLoading.list"
          :columns="columns"
          :page-size="20"
          :fetch-items="refresh"
          default-sort-key="createdAt"
          :default-sort-direction="-1"
        >
          <template v-slot:createdAt="{ data }">
            {{ data.createdAt | date('YYYY-MM-DD HH:mm:ss') }}
          </template>
          <template v-slot:actions="{ data }">
            <div class="d-flex no-wrap">
              <router-link
                v-if="data.format === 'chart'"
                class="btn btn-sm btn-text-primary"
                :to="{
                  name: 'reports-chart',
                  params: { id: data._id }
                }"
                ><i class="fa fa-eye mr-1"></i>View</router-link
              >
              <router-link
                v-if="$auth.isAdmin && data.batchId && data.type === 'pdf'"
                class="btn btn-sm btn-text-warning"
                :to="{
                  name: 'machine-pdf',
                  query: { reportId: data._id, batchId: data.batchId }
                }"
                ><i class="fa fa-lock mr-1"></i>Admin Only - View</router-link
              >
              <router-link
                v-else
                class="btn btn-sm btn-text-primary"
                :to="{
                  name: 'reports-download',
                  params: { id: data._id }
                }"
                ><i class="fa fa-download mr-1"></i>Download</router-link
              >
              <router-link
                v-if="$auth.isAdmin"
                class="btn btn-sm btn-text-info"
                :to="{
                  name: 'reports-download',
                  params: { id: data._id },
                  query: { regenerate: 'true' }
                }"
                ><i class="fa fa-refresh mr-1"></i>Re-generate</router-link
              >
            </div>
          </template>
        </DataTable>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import DataTable from '@/components/DataTable';

export default {
  name: 'ReportHistory',
  components: {
    DataTable
  },
  computed: {
    ...mapGetters({
      reports: 'report/reports',
      reportsTotal: 'report/total',
      reportsLoading: 'report/loadingAction'
    }),
    columns() {
      return [
        { name: 'Name', code: 'fileName', search: true },
        { name: 'Type', code: 'type', search: true },
        { name: 'Sub Type', code: 'subType', search: true },
        { name: 'Status', code: 'status', search: true },
        { name: 'Format', code: 'fileFormat', search: true },
        { name: 'Created At', code: 'createdAt', search: true },
        { name: 'Actions', code: 'actions' }
      ];
    }
  },
  methods: {
    ...mapActions({
      listReports: 'report/list'
    }),
    refresh(params) {
      this.listReports({
        data: {
          ...params.data,
          params: {
            ...params.data.params,
            userSub: this.$auth.user.sub
          }
        }
      });
    }
  }
};
</script>
